import React, { useState, useEffect } from "react"
import { cache } from "swr"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"

import Header from "components/Header"
import Footer from "components/Footer"

import Signup from "./Signup"
import { Account } from "./Account"
import Challenge from "./Challenge"
import Login from "./Login"
import Reset from "./Reset"
import NotFound from "./NotFound"

import addSeconds from "date-fns/addSeconds"
import Cookie from "js-cookie"

import { getSearchParam } from "utils/helpers"
import { useInterval } from "utils/hooks"
import { timer } from "utils/helpers"
import Box from "@material-ui/core/Box"

const isAuthedInitial = Boolean(Cookie.get("id_token"))
const target = getSearchParam("target") || ""
const source = getSearchParam("source") || ""
const action = getSearchParam("action") || ""
let tabToGo = ["billing", "subscriptions", "downloads"].includes(
  target.toString(),
)
  ? target
  : null
if (
  (source === "power-bi-plugin" ||
    source === "tableau-plugin" ||
    source === "excel-plugin") &&
  action === "upgrade"
) {
  tabToGo = "subscriptions"
}

const Routes = () => {
  const [authenticated, setAuthenticated] = useState(isAuthedInitial)
  const [redirectTarget, setRedirectTarget] = useState(tabToGo)
  const [challengeData, setChallengeData] = useState(null)
  const [runRefreshTokenCheck, setRunRefreshTokenCheck] = useState(false)
  const [logOutInMillisecond, setLogOutInMillisecond] = useState(
    process.env.REACT_APP_LOGOUT_INTERVAL,
  ) // Logout interval (ms)
  const refreshTokenInterval = process.env.REACT_APP_REFRESH_INTERVAL //Interval(ms) refresh token to checks the BE
  const redirectSignUp =
    (source === "power-bi-plugin" ||
      source === "tableau-plugin" ||
      source === "excel-plugin") &&
    action === "upgrade"

  useEffect(() => {
    if (window.Chargebee) {
      !window.Chargebee?.inited &&
        window.Chargebee.init({
          site: process.env.REACT_APP_CHARGEBEE_SITE,
          publishableKey: process.env.REACT_APP_CHARGEBEE_KEY,
        })
    }
    checkAuth()

    function checkAuth() {
      const token = Cookie.get("id_token")

      if (!token) {
        setAuthenticated(false)
        return
      }

      // log user out after set time
      timer.start(logUserOut, logOutInMillisecond)
      setAuthenticated(true)
    }
  }, [])

  // A cutom hook to set interval and clear interval
  useInterval(
    () => getRefreshToken(),
    runRefreshTokenCheck ? refreshTokenInterval : null,
  )

  function getRefreshToken() {
    const refreshToken = Cookie.get("refresh_token")
    const url = process.env.REACT_APP_IAM_URL + "/auth/token/refresh"
    const headers = {
      Accept: "application/json, */*",
      "Content-Type": "application/json",
    }
    const data = {
      refreshToken: encodeURI(refreshToken),
    }

    fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.AuthenticationResult) {
          const { AccessToken, IdToken, ExpiresIn } = data?.AuthenticationResult
          const expires = addSeconds(new Date(), ExpiresIn).valueOf()
          Cookie.set("id_token", IdToken, { expires })
          Cookie.set("access_token", AccessToken, { expires })
        } else if (
          data.statusCode === 400 &&
          data.code === "NotAuthorizedException"
        ) {
          logoutState()
        }
      })
      .catch((err) => {
        console.error(err)
        // Log out if there is an error on refresh API
        logoutState()
      })
  }

  const handleLogIn = (response) => {
    const challengeName = response?.data?.ChallengeName

    if (challengeName === "NEW_PASSWORD_REQUIRED") {
      setChallengeData(response.data)
      return
    }

    // remove 2 minutes from the timeout time so that FE logsout before the BE logsout avoiding 401 error.
    const expirySeconds = response?.data?.AuthenticationResult?.ExpiresIn - 120
    const token = response?.data?.AuthenticationResult?.IdToken
    const accessToken = response?.data?.AuthenticationResult?.AccessToken
    const refreshToken = response?.data?.AuthenticationResult?.RefreshToken

    if (!expirySeconds || !token || !accessToken) return

    const expires = addSeconds(new Date(), expirySeconds).valueOf()

    Cookie.set("id_token", token, { expires })
    Cookie.set("access_token", accessToken, { expires })
    Cookie.set("refresh_token", refreshToken, { expires })
    Cookie.set("expiry_date", expires, { expires })

    timer.start(logUserOut, logOutInMillisecond)
    setAuthenticated(true)
    setRunRefreshTokenCheck(true)
    cache.clear()
  }

  const logUserOut = () => {
    const accessToken = Cookie.get("access_token")
    handleLogOutAPI(accessToken)
      .then((res) => res.json())
      .then((data) => {
        console.info(data)
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        logoutState()
      })
  }

  const logoutState = () => {
    cache.clear()
    Cookie.remove("id_token")
    Cookie.remove("access_token")
    Cookie.remove("refresh_token")
    Cookie.remove("expiry_date")
    setAuthenticated(false)
    setRedirectTarget(null)
    setRunRefreshTokenCheck(false)
  }

  const handleLogOutAPI = async (accessToken) => {
    const url = process.env.REACT_APP_IAM_URL + "/auth"
    return await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "X-Access-Token": accessToken,
      },
    })
  }

  const triggerLogOut = ({ delay }) => {
    timer.start(logoutState, delay)
  }

  return (
    <Router>
      {challengeData ? <Redirect to="/challenge" /> : null}
      <Header logOut={logUserOut} authenticated={authenticated} />
      <Box flex={1} display="flex">
        {authenticated ? (
          redirectTarget ? (
            <Switch>
              <Route path="/account">
                <Account triggerLogOut={triggerLogOut} />
              </Route>
              <Redirect from="/" to={`/account/${tabToGo}`} />
            </Switch>
          ) : (
            <Switch>
              <Route path="/account">
                <Account triggerLogOut={triggerLogOut} />
              </Route>
              <Redirect from="/" to="/account" />
            </Switch>
          )
        ) : (
          <Switch>
            <Route path="/signin">
              <Login onLogIn={handleLogIn} />
            </Route>
            <Route path="/challenge">
              <Challenge challengeData={challengeData} onLogIn={handleLogIn} />
            </Route>
            <Route path="/signup">
              {redirectSignUp ? (
                <Redirect to="/signin?target=subscriptions" />
              ) : (
                <Signup />
              )}
            </Route>
            <Route path="/reset" component={Reset} />
            <Route path="/plans">
              <NotFound />
            </Route>
            <Redirect from="/" to="/signin" />
            <Route component={NotFound} />
          </Switch>
        )}
      </Box>
      <Footer />
    </Router>
  )
}

export default Routes
