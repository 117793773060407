import React, { useState, useEffect } from "react"

import Grid from "@material-ui/core/Grid"
import Spacer from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import { Link as RouteLink } from "react-router-dom"

import { api, getRiskLevel } from "utils/api"
import ErrorBox from "ui-elements/ErrorBox"
import Field from "ui-elements/Field"
import Button from "ui-elements/Button"
import { H3 } from "ui-elements/Typography"
import PasswordField from "ui-elements/PasswordField"
import { isEmailValid } from "utils/helpers"

const MAX_TEXTFIELD_LENGTH = 80

// API error messsages
const getErrorMessage = (code) => {
  if (code === "InvalidParameterException")
    return (
      <span>
        Sorry we encountered an error processing your request. Please check your
        details are correct or contact{" "}
        <Link
          href="https://www.arria.com/contact/"
          target="_blank"
          variant="caption"
          underline="always"
        >
          sales.
        </Link>
      </span>
    )
  if (code === "UsernameExistsException")
    return (
      <span>
        An account with the given email already exists. You can click{" "}
        <Link href="/signin" variant="caption" underline="always">
          here
        </Link>{" "}
        to sign in.
      </span>
    )
  if (code === "InvalidPasswordException")
    return "Invalid password, please enter a different valid password."
  return "Something went wrong with your request. Please try again in a few minutes or contact support."
}

export const SignupForm = ({ source, onSubmit }) => {
  const [status, setStatus] = useState("IDLE")
  const [errorMessage, setErrorMessage] = useState(null)

  // Form
  const [email, setEmail] = useState("")
  const [emailErrorMessage, setEmailErrorMessage] = useState()
  const [firstName, setFirstName] = useState("")
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState()
  const [companyName, setCompanyName] = useState("")
  const [companyErrorMessage, setCompanyErrorMessage] = useState()
  const [lastName, setLastName] = useState("")
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState()
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState("")
  const [passwordErrorMessage, setPasswordErrorMessage] = useState()
  const [confirm, setConfirm] = useState("")
  const [confirmErrorMessage, setConfirmErrorMessage] = useState()
  const [recaptchaActive, setRecaptchaActive] = useState(false)
  const [recaptchaError, setRecaptchaError] = useState(false)
  const [riskLevel, setRiskLevel] = useState(false)

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js"
    window.onSubmit = () => {
      setRecaptchaError(false)
      setRecaptchaActive(true)
    }
    window.onRecaptchaExpired = () => {
      setRecaptchaActive(false)
    }
    document.body.appendChild(script)
  }, [])

  const handleFieldChange = ({ target: { value, name } }) => {
    if (status === "LOADING" || status === "SUCCESS") return
    if (status === "ERROR") setStatus("IDLE")

    switch (name) {
      case "email":
        setEmail(value)
        return setEmailErrorMessage(
          !value
            ? "Please enter your email address"
            : value.startsWith(".")
            ? "Email address cannot start with a period"
            : !isEmailValid(value) // : !/\w+@\w[\w.]*$/.test(value)
            ? "This email looks incorrect"
            : value.length > MAX_TEXTFIELD_LENGTH
            ? `Email address exceeds ${MAX_TEXTFIELD_LENGTH} characters`
            : false,
        )
      case "companyName":
        setCompanyName(value)
        return setCompanyErrorMessage(
          !value
            ? "Please enter a company name"
            : value.length < 2
            ? "Company cannot be less than 2 characters"
            : !/^.*[\p{L}]+.*$ ?/giu.test(value)
            ? "Company name must contain at least one letter"
            : false,
        )
      case "firstName":
        setFirstName(value)
        return setFirstNameErrorMessage(
          !value
            ? "Please enter your first name"
            : !/^[\p{L}\p{M}'\s-]+$/giu.test(value)
            ? "First name must only contain letters and non-consecutive space, hyphen or single quote characters"
            : !/.*[\p{L}\p{M}]$/u.test(value)
            ? "First name must end with a letter"
            : value.length > MAX_TEXTFIELD_LENGTH
            ? `First name exceeds ${MAX_TEXTFIELD_LENGTH} characters`
            : false,
        )
      case "lastName":
        setLastName(value)
        return setLastNameErrorMessage(
          !value
            ? "Please enter your surname"
            : !/^[\p{L}\p{M}'\s-]+$/giu.test(value)
            ? "Surname must only contain letters and non-consecutive space, hyphen or single quote characters"
            : !/.*[\p{L}\p{M}]$/u.test(value)
            ? "Surname must end with a letter"
            : value.length > MAX_TEXTFIELD_LENGTH
            ? `Surname exceeds ${MAX_TEXTFIELD_LENGTH} characters`
            : false,
        )

      case "password":
        setPassword(value)
        setConfirmErrorMessage(
          !confirm
            ? confirmErrorMessage
            : confirm !== value
            ? "Passwords do not match"
            : false,
        )
        return setPasswordErrorMessage(
          !value
            ? "Please enter a password"
            : value.length < 8
            ? "Password should be at least 8 characters long"
            : !/[0-9]/.test(value)
            ? "Passwords must contain at least 1 number"
            : !/[A-Z]/.test(value)
            ? "Passwords must contain at least 1 uppercase character"
            : !/[a-z]/.test(value)
            ? "Passwords must contain at least 1 lowercase character"
            : !/^\S+$/.test(value)
            ? "Passwords cannot contain spaces"
            : value.length > MAX_TEXTFIELD_LENGTH
            ? `Password exceeds ${MAX_TEXTFIELD_LENGTH} characters`
            : false,
        )
      case "confirm":
        setConfirm(value)
        return setConfirmErrorMessage(
          !value
            ? "Please enter the password again"
            : value !== password
            ? "Passwords do not match"
            : false,
        )
      default:
        return console.error("invalid field.")
    }
  }

  const validForm = (_) => {
    if (
      emailErrorMessage ||
      firstNameErrorMessage ||
      lastNameErrorMessage ||
      companyErrorMessage ||
      passwordErrorMessage ||
      confirmErrorMessage
    )
      return false

    // This needs to be done on first submit
    if (
      !email ||
      !firstName ||
      !lastName ||
      !companyName ||
      !password ||
      !confirm
    ) {
      handleFieldChange({ target: { value: email, name: "email" } })
      handleFieldChange({ target: { value: firstName, name: "firstName" } })
      handleFieldChange({ target: { value: lastName, name: "lastName" } })
      handleFieldChange({ target: { value: companyName, name: "companyName" } })
      handleFieldChange({ target: { value: password, name: "password" } })
      handleFieldChange({ target: { value: confirm, name: "confirm" } })
      return false
    }

    return true
  }

  const handleKeyDown = ({ key }) => {
    if (key === "Enter") submit()
  }

  const handleRego = async (_) => {
    setStatus("LOADING")
    setRiskLevel(false)
    const riskLevel = await getRiskLevel(email)
    if (riskLevel === 5) {
      setRiskLevel(true)
      setStatus("ERROR")      
      return
    }

    const url = process.env.REACT_APP_IAM_URL + "/signup/user"
    const data = {
      email,
      firstName,
      lastName,
      password,
      companyName: companyName.trim(),
    }

    data.source = source || "online-subscription"

    const res = await api.post(url, data).catch((error) => {
      setStatus("ERROR")
      return setErrorMessage(getErrorMessage(error.data?.code))
    })

    return res?.status === 201 && onSubmit({ ...data, riskLevel })
  }

  const submit = async (_) => {
    if (!validForm()) return
    if (!recaptchaActive) return setRecaptchaError(true)
    return handleRego()
  }

  const riskMsg = <span> Sorry, we were not able to create your account, please contact <Link
  href="https://www.arria.com/contact/"
  target="_blank"
      variant="caption"
      underline="always"
>
  sales.
</Link></span>
  const error = (
    <ErrorBox>
      {riskLevel ?  riskMsg : errorMessage}
    </ErrorBox>
    
  )

  return (
    <>
      <H3 align="center">Get started now</H3>
      <Spacer m={3} />
      <Typography variant="subtitle2" component="p" align="center">
        If you are an existing user, please log in{" "}
        <Link component={RouteLink} to="/signin">
          here.
        </Link>
      </Typography>
      <Spacer m={10} />
      {status === "ERROR" && error}
      <Spacer m={10} />
      <Grid container spacing={2} justify="center">
        <Field
          xs={6}
          label="First Name"
          type="text"
          name="firstName"
          value={firstName}
          onChange={handleFieldChange}
          error={!!firstNameErrorMessage}
          helperText={firstNameErrorMessage}
          autoFocus
          required
        />
        <Field
          xs={6}
          type="text"
          label="Last Name"
          name="lastName"
          value={lastName}
          onChange={handleFieldChange}
          error={!!lastNameErrorMessage}
          helperText={lastNameErrorMessage}
          required
        />
        <Field
          xs={6}
          label="Company"
          name="companyName"
          value={companyName}
          onChange={handleFieldChange}
          error={!!companyErrorMessage}
          helperText={companyErrorMessage}
          required
        />
        <Field
          xs={6}
          label="Email address"
          name="email"
          type="email"
          value={email}
          onChange={handleFieldChange}
          error={!!emailErrorMessage}
          helperText={emailErrorMessage}
          required
        />
        <PasswordField
          xs={6}
          label="Password"
          name="password"
          autoComplete="new-password"
          value={password}
          onChange={handleFieldChange}
          error={!!passwordErrorMessage}
          helperText={passwordErrorMessage}
          required
          showPassword={showPassword}
          onToggleShowPassword={() => setShowPassword(!showPassword)}
        />
        <PasswordField
          xs={6}
          label="Confirm Password"
          name="confirm"
          autoComplete="new-password"
          value={confirm}
          onChange={handleFieldChange}
          error={!!confirmErrorMessage}
          helperText={confirmErrorMessage}
          required
          showPassword={showPassword}
          onToggleShowPassword={() => setShowPassword(!showPassword)}
          onKeyDown={handleKeyDown}
        />
      </Grid>
      <Spacer m={3} />
      <Typography variant="subtitle2" component="p" align="center">
        By continuing you agree to our{" "}
        <Link
          href="https://docs.app.studio.arria.com/en/12743-terms-and-conditions.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms and conditions
        </Link>
        .
      </Typography>
      <Spacer m={3} />
      <div
        align="center"
        className="g-recaptcha"
        data-sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
        data-callback="onSubmit"
        data-expired-callback="onRecaptchaExpired"
      ></div>
      {recaptchaError && (
        <>
          <Spacer m={2} />
          <div style={{ color: "red" }}>
            Please select the checkbox and then proceed.
          </div>
        </>
      )}
      <Spacer m={3} />
      <div className="center-content">
        <Button
          loading={status === "LOADING"}
          onClick={submit}
          style={{ maxWidth: 300 }}
          fullWidth
        >
          Continue
        </Button>
      </div>
      <Spacer m={3} />
      <Typography variant="subtitle2" component="p" align="center">
        If you are an existing user, please log in{" "}
        <Link component={RouteLink} to="/signin">
          here.
        </Link>
      </Typography>
      
    </>
  )
}
