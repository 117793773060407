import { format, fromUnixTime } from "date-fns"

const patterns = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  password: /(?=.*[a-z])(?=.*[A-Z])/,
}

export const isEmailValid = (email) => patterns.email.test(email.toLowerCase())
export const isPasswordValid = (password) => patterns.password.test(password)

export const isEmpty = (xs) =>
  !Boolean(xs)
    ? true
    : Array.isArray(xs)
    ? xs.length === 0
    : typeof xs === "object" && isEmpty(Object.keys(xs))

// Delays fn execution by passed delay in milliseconds
//
// usage: timer.start(callback, delay)
export const timer = {
  start(fn, delayMillis) {
    if (this[fn]) {
      return "Timer for this function already exists"
    }

    this[fn] = setTimeout(() => {
      fn()
      this[fn] = null
    }, delayMillis)
  },
}

export const toTitleCase = (str) =>
  str
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(" ")

export const unix2date = (timeStamp) =>
  format(fromUnixTime(timeStamp), "MMM d, yyyy")

export const getSearchParam = (key) => {
  const params = new URLSearchParams(window.location.search)
  return typeof key === "string" ? params.get(key) : params
}

export const formattedPlanPrice = (price) => {
  if (!price) return
  const priceFloat = parseFloat(price)
  return Number.isInteger(priceFloat) ? priceFloat.toFixed() : priceFloat
}
