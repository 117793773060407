import React from "react"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

export const Title = (props) => {
  const classes = useStyles()

  const { children, ...other } = props

  return (
    <Typography className={classes.title} {...other}>
      {children || "Title"}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: theme.spacing(4.25),
    margin: theme.spacing(8.5, 0),
  },
}))

Title.propTypes = {
  children: PropTypes.node,
}

export default Title
