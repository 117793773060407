import React from "react"
import { FormHelperText, Grid, Input, InputLabel } from "@material-ui/core"
import "assets/styles/mui-input.css"

export const Field = ({
  xs = 6,
  label,
  error = undefined,
  helperText = "",
  ...other
}) => (
  <Grid item xs={xs}>
    <InputLabel htmlFor={label}>{label}</InputLabel>
    <Input id={label} error={error} fullWidth {...other} />

    <FormHelperText id={label} className="warning">
      {helperText}
      {/* &nbsp; */}
    </FormHelperText>
  </Grid>
)

export default Field
