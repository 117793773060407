import React from "react"
import MuiButton from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import { withStyles } from "@material-ui/core/styles"

export const Button = withStyles(({ palette, spacing }) => ({
  root: {
    minWidth: spacing(30),
    height: spacing(10),
    textTransform: "unset",
    fontWeight: "bold",
    borderRadius: 0,
    borderColor: palette.primary.main,
    borderWidth: 2,
    "&.Mui-disabled": {
      background: "#f5f5f5",
    },
  },
  small: {
    height: spacing(7),
    fontSize: 13,
  },
  link: {
    minWidth: "unset",
    padding: 0,
    border: "none",
    textDecoration: "underline",
    "&:hover": {
      border: "none",
    },
  },
  text: {
    minWidth: "unset",
  },
}))(
  ({
    classes,
    className,
    children,
    size,
    link = false,
    loading = false,
    ...rest
  }) => {
    rest.variant = rest.variant ?? "outlined"

    const clss =
      classes.root +
      " " +
      className +
      (rest.variant === "text" ? ` ${classes.text}` : "") +
      (size === "small" ? ` ${classes.small}` : "") +
      (link ? ` ${classes.link}` : "")

    return (
      <MuiButton
        color="primary"
        className={clss}
        disabled={loading}
        disableElevation
        {...rest}
      >
        {loading ? <CircularProgress size={25} /> : children || "Button"}
      </MuiButton>
    )
  },
)

export default Button
