import React, { useState } from "react"
import { useHistory } from "react-router-dom"

import { mutate } from "swr"

import Alert from "@material-ui/lab/Alert"
import CircularProgress from "@material-ui/core/CircularProgress"
import Box from "@material-ui/core/Box"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

import { isEmpty, unix2date /*, toTitleCase*/ } from "utils/helpers"
import {
  getPdfInvoiceUrl,
  makePayment,
  updatePaymentMethod,
  useInvoices,
} from "utils/api"
import { CenteredProgress } from "ui-elements/progress"
import { H4, Label } from "ui-elements/Typography"
import { Button } from "ui-elements/Button"
import "assets/styles/invoices.css"

const downloadResource = (url) => {
  const a = document.createElement("a")
  a.href = url
  a.dispatchEvent(new MouseEvent("click"))
}

export const Invoices = (props) => {
  const { invoices, isLoading } = useInvoices()
  const [alert, setAlert] = useState()

  if (isLoading) return <CenteredProgress />
  if (isEmpty(invoices))
    return (
      <Label light color="textSecondary" align="center">
        After you subscribe to a plan, the issued invoices will be listed here.
        <br />
        You currently do not have any invoices.
      </Label>
    )

  return (
    <>
      <H4>Invoices</H4>
      <Box m={5} />
      {!isEmpty(alert) && (
        <Alert severity={alert.severity}>{alert.message}</Alert>
      )}
      <TableContainer>
        <Table className="invoices">
          <TableHead>
            <TableRow className="header">
              <TableCell>Issued On</TableCell>
              <TableCell>Invoice No.</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="body">
            {invoices.map((inv) => (
              <Invoice
                key={inv.invoiceId}
                invoice={inv}
                setAlert={setAlert}
                {...props}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const Invoice = ({ invoice, baseUrl, continueFn, setAlert }) => {
  const [inProgress, setInprogress] = useState("") // payment || download
  const history = useHistory()

  const fns = {
    hideAlert: () => setAlert(false),
    showInvoices: () => history.push(baseUrl + "/invoices"),
    getPdf: (invoiceId) => () => {
      setInprogress("download")
      getPdfInvoiceUrl(invoiceId)
        .then((url) => downloadResource(url))
        .finally(() => setInprogress(false))
    },
    pay: (invoiceId) => () => {
      setInprogress("payment")
      makePayment(invoiceId)
        // .then((res) => console.log("success response", res))
        .catch((err) => {
          const code = err.data?.api_error_code

          if (code === "payment_method_not_present") {
            continueFn.current = (t) => {
              const req = updatePaymentMethod(t).then(() => fns.pay(invoiceId))

              // TODO: make fns.pay always return a promise
              req?.then
                ? req.then(fns.showInvoices)
                : setTimeout(fns.showInvoices, 1000)
            }

            return
          }

          setAlert({
            severity: "error",
            message:
              "There was a problem verifying your payment details. Please check that your card details are entered correctly.",
          })
        })
        .finally(() => {
          setInprogress(false)
          mutate("/account/invoices")
        })
    },
  }

  const description =
    invoice.lineItems.length <= 1 ? (
      invoice.lineItems[0]?.name
    ) : (
      <em color="var(--primary)">Multiple items</em>
    )

  return (
    <TableRow>
      <TableCell>{unix2date(invoice.date)}</TableCell>
      <TableCell>#{invoice.invoiceId}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell className="amount">${invoice.displayTotal}</TableCell>
      <TableCell>
        <Button
          onClick={fns.getPdf(invoice.invoiceId)}
          size="small"
          variant="text"
          disabled={inProgress === "download"}
        >
          {inProgress === "download" ? (
            <CircularProgress size="1.3rem" />
          ) : (
            "Download"
          )}
        </Button>
        {invoice.status !== "paid" && (
          <Button
            onClick={fns.pay(invoice.invoiceId)}
            size="small"
            disabled={inProgress === "payment"}
          >
            {inProgress === "payment" ? (
              <CircularProgress size="1.3rem" />
            ) : (
              "Pay Now"
            )}
          </Button>
        )}
      </TableCell>
    </TableRow>
  )
}
