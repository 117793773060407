import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { api } from "../utils/api"
import { Link as RouteLink } from "react-router-dom"
import { Container, Box as Spacer, Grid, Link } from "@material-ui/core"

import Field from "ui-elements/Field"
import PasswordField from "ui-elements/PasswordField"
import Button from "ui-elements/Button"
import ErrorBox from "ui-elements/ErrorBox"
import { H3 } from "ui-elements/Typography"

import ConfirmationCode from "components/ConfirmationCode"

// Form empty/default messages
const errorMsgs = {
  email: "Please enter your email address",
  password: "Please enter your password",
}

// API error messsages
const getErrorMessage = (code) => {
  if (code === "UserNotFoundException") return "Unknown username or password"
  if (code === "NotAuthorizedException") return "Unknown username or password"
  // "InvalidParameterException" ?TODO
  return "Unable to log in. Please contact support."
}

export const Login = ({ onLogIn }) => {
  const pristine = useRef(true)

  const [status, setStatus] = useState("IDLE")
  const [errorMessage, setErrorMessage] = useState("")

  const [confirm, setConfirm] = useState(false)

  const [email, setEmail] = useState("")
  const [emailErrorMessage, setEmailErrorMessage] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState("")
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null)

  const handleFieldChange = ({ target: { value, name } }) => {
    if (status === "LOADING" || status === "SUCCESS") return
    if (status === "ERROR") setStatus("IDLE")

    pristine.current = false

    switch (name) {
      case "email":
        setEmail(value)
        return setEmailErrorMessage(
          !value?.trim()
            ? "Please enter your email address"
            : !/.+@.+/.test(value)
            ? "Please enter a valid email address"
            : false,
        )

      case "password":
        setPassword(value)
        setPasswordErrorMessage(!value ? errorMsgs.password : false)
        return
      default:
        return console.error("invalid field.")
    }
  }

  const handleUserNotConfirmed = async (_) => {
    const url = process.env.REACT_APP_IAM_URL + "/signup/user/verify/resend"
    const data = { email }

    const res = await api.put(url, data).catch((error) => {
      setStatus("ERROR")
      return setErrorMessage(getErrorMessage(error.data?.code))
    })

    return res?.status === 200 && setConfirm(true)
  }

  const handleUserAuth = async (_) => {
    setStatus("LOADING")
    const url = process.env.REACT_APP_IAM_URL + "/auth"
    const data = { email: email.trim(), password }

    const res = await api.post(url, data).catch((error) => {
      if (error.data?.code === "UserNotConfirmedException")
        return handleUserNotConfirmed()
      setStatus("ERROR")
      return setErrorMessage(getErrorMessage(error.data?.code))
    })
    res?.status === 200 && setStatus("SUCCESS")
    return res?.status === 200 && onLogIn(res)
  }

  const submit = async (ev) => {
    // prevent redirection (html form default behaviour)
    ev.preventDefault()

    if (status === "SUCCESS") return
    // TODO: Add indication to enter email || password
    if (emailErrorMessage || passwordErrorMessage) return

    if (pristine.current || !email?.trim() || !password) {
      handleFieldChange({ target: { name: "email", value: email } })
      handleFieldChange({ target: { name: "password", value: password } })
      return
    }
    return handleUserAuth()
  }

  if (confirm)
    return (
      <Container maxWidth="xs" align="center">
        <Spacer m={15} />
        <ConfirmationCode
          email={email}
          onSubmit={async (response) => {
            if (response?.status === 200) {
              setStatus("LOADING")
              return handleUserAuth()
            }
          }}
        />
      </Container>
    )

  const form = (
    <Grid container direction="column" spacing={2}>
      <Field
        xs={12}
        label="Email address"
        name="email"
        type="email"
        value={email}
        onChange={handleFieldChange}
        error={!!emailErrorMessage}
        helperText={emailErrorMessage}
        autoFocus
      />
      <PasswordField
        xs={12}
        label="Password"
        name="password"
        value={password}
        onChange={handleFieldChange}
        error={!!passwordErrorMessage}
        helperText={passwordErrorMessage}
        showPassword={showPassword}
        onToggleShowPassword={() => setShowPassword(!showPassword)}
      />
    </Grid>
  )

  const links = (
    <>
      <Link component={RouteLink} to="/reset" variant="caption">
        Forgotten your password?
      </Link>
      <Spacer m={3} />
      <Link
        href="https://www.arria.com/contact/"
        target="_blank"
        variant="caption"
      >
        Don't have an account? Please contact our sales team.
      </Link>
    </>
  )

  const error = <ErrorBox>{errorMessage}</ErrorBox>

  return (
    <Container component="main" style={{ maxWidth: "330px" }}>
      <Spacer m={15} />
      <H3 align="center">Log in to Arria</H3>
      <Spacer m={10} />

      {status === "ERROR" && error}
      <form onSubmit={submit}>
        <Spacer m={2} />
        {form}
        <Spacer m={2} />
        <Button
          fullWidth
          type="submit"
          onClick={submit}
          loading={status === "LOADING"}
        >
          Log in
        </Button>
      </form>
      <Spacer m={4} />
      {links}
    </Container>
  )
}

Login.propTypes = {
  onLogIn: PropTypes.func.isRequired,
}

export default Login
