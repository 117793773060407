import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

const ErrorBox = (props) => {
  const classes = useStyles()

  const { children, ...other } = props

  return (
    <div className={classes.root} {...other}>
      <p className={classes.text}>{children || "Error"}</p>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    border: `1px solid ${theme.palette.error.main}`,
    background: theme.palette.error.light,
    color: theme.palette.error.main,
    borderRadius: 3,
  },
  text: {
    fontSize: 12,
    textAlign: "center",
  },
}))

ErrorBox.propTypes = {
  children: PropTypes.node,
}

export default ErrorBox
