import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import Container from "@material-ui/core/Container"
import "assets/styles/progress.css"

export const CenteredProgress = ({ cover = false }) => (
  <Container className={"center-content progress" + (cover ? " cover" : "")}>
    <CircularProgress />
  </Container>
)
