import React from "react"
import Field from "./Field"
import { InputAdornment, IconButton } from "@material-ui/core"
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons"

const PasswordField = ({ showPassword, onToggleShowPassword, ...other }) => {
  const iconStyle = { fontSize: 20 }

  const PasswordVisibilityToggleIcon = (
    <InputAdornment position="end">
      <IconButton
        onClick={onToggleShowPassword}
        aria-label="toggle password visibility"
        size="medium"
        edge="end"
        style={{ color: "#aaa", marginRight: 0 }}
        tabIndex="-1"
        disableRipple
        disableFocusRipple
      >
        {showPassword ? (
          <VisibilityOffIcon style={iconStyle} />
        ) : (
          <VisibilityIcon style={iconStyle} />
        )}
      </IconButton>
    </InputAdornment>
  )

  return (
    <Field
      type={showPassword ? "text" : "password"}
      {...other}
      endAdornment={PasswordVisibilityToggleIcon}
    />
  )
}

export default PasswordField
