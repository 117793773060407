import React, { useState, useEffect } from "react"
import Cookie from "js-cookie"

import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

import Field from "ui-elements/Field"
import Button from "ui-elements/Button"
import PasswordField from "ui-elements/PasswordField"
import Title from "components/Title"
import { api, useCustomer } from "utils/api"

export const CustomerDetailsForm = ({ showDialog, notify, triggerLogOut }) => {
  const { customer = {} } = useCustomer()
  const [status, setStatus] = useState("IDLE")

  // Form
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [companyName, setCompany] = useState("")
  const [currentPassword, setCurrentPassword] = useState("")
  const [currentPasswordErrorMsg, setCurrentPasswordErrorMsg] = useState(false)
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordErrorMsg, setNewPasswordErrorMsg] = useState(false)
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [confirmNewPasswordErrorMsg, setConfirmNewPasswordErrorMsg] = useState(
    false,
  )
  const [passwordVisible, setPasswordVisible] = useState(false)

  const MAX_TEXTFIELD_LENGTH = 255

  useEffect(() => {
    if (customer) {
      setEmail(customer.email)
      setFirstName(customer.firstName)
      setLastName(customer.lastName)
      customer.company && setCompany(customer.company)
    }
  }, [customer])

  const handlePasswordChange = async (_) => {
    setStatus("LOADING")
    const url = process.env.REACT_APP_IAM_URL + "/idm/user/password/change"
    const payload = {
      existingPassword: currentPassword,
      newPassword,
      accessToken: Cookie.get("access_token"),
    }

    return await api
      .patch(url, payload)
      .then((res) => {
        setStatus("IDLE")
        if (res?.status === 200) {
          notify({
            severity: "success",
            message:
              "Successfully changed your password. You will need to log in again.",
          })
          setCurrentPassword("")
          setNewPassword("")
          setConfirmNewPassword("")
          triggerLogOut({ delay: 4000 })
        }
      })
      .catch((error) => {
        console.log(error)
        setStatus("ERROR")
        notify({
          severity: "error",
          message:
            error.message || "Something went wrong resetting your password",
        })
        return error.data?.code
      })
  }

  const handleFieldChange = ({ target: { value, name } }) => {
    if (status === "LOADING" || status === "SUCCESS") return
    if (status === "ERROR") setStatus("IDLE")

    switch (name) {
      case "currentPassword":
        setCurrentPassword(value)
        return setCurrentPasswordErrorMsg(
          !value ? "Please enter the password again" : false,
        )

      case "newPassword":
        setNewPassword(value)
        setNewPasswordErrorMsg(
          !confirmNewPassword
            ? confirmNewPasswordErrorMsg
            : confirmNewPassword !== value
            ? "Passwords do not match"
            : false,
        )
        return setNewPasswordErrorMsg(
          !value
            ? "Please enter a new password"
            : value.length < 8
            ? "Password should be at least 8 characters long"
            : !/[0-9]/.test(value)
            ? "Passwords must contain at least 1 number"
            : !/[A-Z]/.test(value)
            ? "Passwords must contain at least 1 uppercase character"
            : !/[a-z]/.test(value)
            ? "Passwords must contain at least 1 lowercase character"
            : !/^\S+$/.test(value)
            ? "Passwords cannot contain spaces"
            : value.length > MAX_TEXTFIELD_LENGTH
            ? `Password exceeds ${MAX_TEXTFIELD_LENGTH} characters`
            : false,
        )
      case "confirmNewPassword":
        setConfirmNewPassword(value)
        return setConfirmNewPasswordErrorMsg(
          !value
            ? "Please enter the password again"
            : value !== newPassword
            ? "Passwords do not match"
            : false,
        )
      default:
        return console.error("invalid field.")
    }
  }

  return (
    <>
      <Grid container spacing={10}>
        <Grid item xs={12} sm={6} md={4}>
          <Title>Personal details</Title>
          <Field
            xs={12}
            label="First name"
            type="text"
            name="firstName"
            value={firstName}
            required
            disabled={true}
            disableUnderline={true}
          />
          <Box m={5} />
          <Field
            xs={12}
            type="text"
            label="Last name"
            name="lastName"
            value={lastName}
            required
            disabled={true}
            disableUnderline={true}
          />
          <Box m={5} />
          <Field
            xs={12}
            label="Company"
            name="company"
            value={companyName}
            disabled={true}
            disableUnderline={true}
            required
          />
          <Box m={5} />
          <Field
            xs={12}
            label="Primary email"
            name="email"
            type="email"
            value={email}
            disabled={true}
            disableUnderline={true}
            required
          />
          <Box m={5} />
          <Button onClick={showDialog} size="small">
            Request update
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Title>Change password</Title>
          <PasswordField
            xs={12}
            label="Current password"
            name="currentPassword"
            autoComplete="new-password"
            value={currentPassword}
            onChange={handleFieldChange}
            error={!!currentPasswordErrorMsg}
            helperText={currentPasswordErrorMsg}
            showPassword={passwordVisible}
            onToggleShowPassword={() => setPasswordVisible(!passwordVisible)}
            required
          />
          <Box m={5} />
          <PasswordField
            xs={12}
            label="New password"
            name="newPassword"
            autoComplete="new-password"
            value={newPassword}
            onChange={handleFieldChange}
            error={!!newPasswordErrorMsg}
            helperText={newPasswordErrorMsg}
            showPassword={passwordVisible}
            onToggleShowPassword={() => setPasswordVisible(!passwordVisible)}
            required
          />
          <Box m={5} />
          <PasswordField
            xs={12}
            label="Confirm new password"
            name="confirmNewPassword"
            autoComplete="new-password"
            value={confirmNewPassword}
            onChange={handleFieldChange}
            error={!!confirmNewPasswordErrorMsg}
            helperText={confirmNewPasswordErrorMsg}
            showPassword={passwordVisible}
            onToggleShowPassword={() => setPasswordVisible(!passwordVisible)}
            required
          />
          <Box m={5} />
          <Button
            loading={status === "LOADING"}
            onClick={handlePasswordChange}
            size="small"
            disabled={
              !!currentPasswordErrorMsg ||
              !!newPasswordErrorMsg ||
              !!confirmNewPasswordErrorMsg ||
              !currentPassword ||
              !newPassword ||
              !confirmNewPassword
            }
          >
            Update password
          </Button>
        </Grid>
        <Box m={5} />
      </Grid>
    </>
  )
}
