import React from "react"

import { Container, Box, Typography } from "@material-ui/core"

import Button from "ui-elements/Button"

export default () => (
  <Container maxWidth="xs">
    <Box m={10} />
    <Typography variant="h1" align="center">
      404
    </Typography>
    <Typography variant="h4" align="center">
      Page Not Found
    </Typography>
    <Box m={20} />
    <Button onClick={() => window.history.back()} fullWidth>
      Previous Page
    </Button>
  </Container>
)
