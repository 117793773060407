import React from "react"

import Box from "@material-ui/core/Box"
import { withStyles } from "@material-ui/core/styles"

export const Card = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
    border: "1px solid " + theme.palette.grey[300],
    borderRadius: theme.spacing(1),
    background: "white",
  },
}))(({ classes, className, ...rest }) => (
  <Box className={`${classes.root} ${className}`} {...rest} />
))
