import React from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import { StylesProvider } from "@material-ui/core/styles"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { SWRConfig } from "swr"

import Routes from "pages/routes"
import theme from "../theme"

import "assets/styles/App.css"

const swrOptions = {
  errorRetryCount: 3,
  refreshInterval: 0,
  revalidateOnFocus: false,
  shouldRetryOnError: true,
}
export default () => (
  <MuiThemeProvider theme={theme}>
    <SWRConfig value={swrOptions}>
      <StylesProvider injectFirst>
        <CssBaseline />
        <Routes />
      </StylesProvider>
    </SWRConfig>
  </MuiThemeProvider>
)
