import { createMuiTheme } from "@material-ui/core/styles"

// prettier-ignore
export const colors = {
       blue: "#0071E3", // hsl(210, 100%, 45%)
   darkBlue: "#0057AF",
  lightBlue: "#f0f4f9",
     orange: "#FF6E0D",
    powerBi: "#f2c80f",
      excel: "#3ea798",
}

// xs - sm : mobile(portrait)
// sm - md: tablet
// md - lg: laptop
// lg - xl: desktop

const theme = createMuiTheme({
  spacing: 5,
  palette: {
    primary: {
      main: colors.blue,
      dark: colors.darkBlue,
      light: colors.lightBlue,
    },
    error: {
      main: "#ff003b",
      light: "#ffe3e9",
    },
    secondary: { main: colors.orange },
    common: {
      black: "hsl(210, 100%, 10%)",
      white: "hsl(210, 70%, 99%)",
    },
  },
  props: {
    MuiTextField: {
      autoComplete: "none",
      autoCorrect: "none",
    },
    MuiButtonBase: {
      // The default props to change
      disableTouchRipple: true, // No more ripple on the app
    },
    MuiButton: {
      disableFocusRipple: true,
    },
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 16,
    fontFamily: "Open Sans, sans-serif",
    h4: { fontWeight: 700, fontSize: "2.2rem" },
    h5: { fontWeight: 700, fontSize: "1.25rem" },
    h6: { fontWeight: 700, fontSize: "1rem" },
  },
  shape: {
    borderRadius: 2,
  },
})
export default theme
