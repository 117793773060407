import React, { useState } from "react"
import PropTypes from "prop-types"
import { api } from "../utils/api"
import { Container, Box, Grid } from "@material-ui/core"
import { Text, H3 } from "ui-elements/Typography"

import PasswordField from "../ui-elements/PasswordField"
import Button from "../ui-elements/Button"
import ErrorBox from "../ui-elements/ErrorBox"

// API error messsages
const getErrorMessage = (code) => {
  return "Something went wrong with your request. Please try again in a few minutes or contact support."
}

const MAX_TEXTFIELD_LENGTH = 255

const Challenge = ({ challengeData, onLogIn }) => {
  const [status, setStatus] = useState("IDLE")
  const [errorMessage, setErrorMessage] = useState(null)

  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(false)
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState(false)

  const handleFieldChange = ({ target: { value, name } }) => {
    if (status === "LOADING" || status === "SUCCESS") return
    if (status === "ERROR") setStatus("IDLE")

    switch (name) {
      case "password":
        setPassword(value)
        setConfirmPasswordErrorMsg(
          !confirmPassword
            ? confirmPasswordErrorMsg
            : confirmPassword !== value
            ? "Passwords do not match"
            : false,
        )
        return setPasswordErrorMessage(
          !value
            ? "Please choose a password"
            : value.length < 8
            ? "Password should be at least 8 characters long"
            : !/[0-9]/.test(value)
            ? "Passwords must contain at least 1 number"
            : !/[A-Z]/.test(value)
            ? "Passwords must contain at least 1 uppercase character"
            : !/[a-z]/.test(value)
            ? "Passwords must contain at least 1 lowercase character"
            : value.length > MAX_TEXTFIELD_LENGTH
            ? `Password exceeds ${MAX_TEXTFIELD_LENGTH} characters`
            : false,
        )
      case "confirmPassword":
        setConfirmPassword(value)
        return setConfirmPasswordErrorMsg(
          !value
            ? "Please enter the password again"
            : value !== password
            ? "Passwords do not match"
            : false,
        )
      default:
        console.log(name)
        return console.error("invalid field.")
    }
  }

  const handleReset = async (_) => {
    if (!challengeData) {
      setStatus("ERROR")
      return setErrorMessage(getErrorMessage())
    }

    setStatus("LOADING")
    const url = process.env.REACT_APP_IAM_URL + "/auth/challenge/response"
    const data = {
      session: challengeData.Session,
      challengeName: challengeData.ChallengeName,
      challengeParameters: {
        userId: challengeData.ChallengeParameters.USER_ID_FOR_SRP,
        newPassword: password,
      },
    }

    const res = await api.post(url, data).catch((error) => {
      return setErrorMessage(getErrorMessage(error.data?.code))
    })

    res?.status === 200 && setStatus("SUCCESS")
    return res?.status === 200 && onLogIn(res)
  }

  const submit = async (_) => {
    if (status === "SUCCESS") return
    // TODO: Add indication to enter email
    if (passwordErrorMessage || confirmPasswordErrorMsg) return
    return handleReset()
  }

  const form = (
    <Grid container direction="column" spacing={2}>
      <PasswordField
        xs={12}
        label="Password"
        name="password"
        value={password}
        onChange={handleFieldChange}
        error={!!passwordErrorMessage}
        helperText={passwordErrorMessage}
        required
        showPassword={showPassword}
        onToggleShowPassword={() => setShowPassword(!showPassword)}
      />
      <PasswordField
        xs={12}
        label="Confirm Password"
        name="confirmPassword"
        autoComplete="new-password"
        value={confirmPassword}
        onChange={handleFieldChange}
        error={!!confirmPasswordErrorMsg}
        helperText={confirmPasswordErrorMsg}
        required
        showPassword={showPassword}
        onToggleShowPassword={() => setShowPassword(!showPassword)}
      />
    </Grid>
  )

  const error = (
    <ErrorBox>
      <b>There was an error resetting your password.</b>
      <br /> {errorMessage}
    </ErrorBox>
  )

  return (
    <Container maxWidth="xs" align="center">
      <Box m={20} />
      <H3 align="center" style={{ lineHeight: "41px" }}>
        New password
      </H3>
      <Box maxWidth="365px" margin="auto">
        <Box m={6} />
        <Text align="center" light>
          Please enter a new password to finish signing in.
        </Text>
        <Box m={6} />
        {form}
        <Box m={6} />
        <Button
          type="submit"
          onClick={submit}
          loading={status === "LOADING"}
          disabled={
            !!passwordErrorMessage ||
            !!confirmPasswordErrorMsg ||
            !password ||
            !confirmPassword
          }
          fullWidth
        >
          Submit
        </Button>
        {status === "ERROR" && error}
      </Box>
    </Container>
  )
}

Challenge.propTypes = {
  challengeData: PropTypes.object,
  onLogIn: PropTypes.func.isRequired,
}

export default Challenge
