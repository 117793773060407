import React, { useState, useRef, useEffect } from "react"

import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"

import "assets/styles/payment-method.css"
import "assets/styles/order-summary.css"
import Field from "ui-elements/Field"
import { Button } from "ui-elements/Button"
import { Card } from "ui-elements/Card"
import {
  CardComponent,
  CardNumber,
  CardExpiry,
  CardCVV,
} from "@chargebee/chargebee-js-react-wrapper"
import { CardSummary } from "components/CardSummary"
import { CenteredProgress } from "ui-elements/progress"
import ErrorBox from "ui-elements/ErrorBox"
import { H3, H4, Text, Large } from "ui-elements/Typography"
import {
  usePaymentDetails,
  usePlans,
  getRiskLevel,
  createPaymentIntent,
} from "utils/api"

import visa from "assets/images/visa.svg"
import mastercard from "assets/images/mastercard.svg"
import amex from "assets/images/amex.svg"

const SUPPORTED_CARDS = ["visa", "mastercard", "amex"]
const areFieldsValid = (fields) =>
  Object.values(fields).reduce((a, x) => a && x.complete && !x.error, true)

export const OrderSummary = ({ onSubmit, selectedPlanId, user, loading }) => {
  const { paymentDetails, isLoading } = usePaymentDetails()
  const { plans } = usePlans(selectedPlanId)

  const cardRef = useRef(null)

  // 'add-card' || 'setup-later'
  const [paymentOption, setPaymentOption] = useState("add-card")
  const company = user?.company || ""
  const [riskLevel, setRiskLevel] = useState(user?.riskLevel)
  const [inProgress, setInprogress] = useState(false)
  const [intent, setIntent] = useState(null)
  const [cardError, setCardError] = useState(false)

  const [fields, setFields] = useState({
    cvv: {},
    expiry: {},
    nameOnCard: { value: "" },
    number: {},
  })

  const selectedPlan = plans?.find((p) => p.id === selectedPlanId)

  const selectedPlanPrice = selectedPlan?.price

  useEffect(() => {
    if (selectedPlanPrice) {
      // Get selected plan price an convert in cents and fallback to our highest plan price
      createPaymentIntent({
        amount: (parseInt(selectedPlanPrice) * 100).toFixed(0),
        currency: "USD",
        paymentMethodType: "card",
      })
        .then((intent) => {
          setIntent(intent.data)
        })
        .catch((err) => console.log(err))
        .finally()
    }
  }, [selectedPlanPrice])

  useEffect(() => {
    if (typeof user.riskLevel === "number") return

    user.email && getRiskLevel(user.email).then((level) => setRiskLevel(level))
  }, [user.email, user.riskLevel])

  // Card details are required if the user's email is considered "risky"
  const cardRequired = riskLevel >= 3

  const fns = {
    ref: cardRef,
    init: (e) => setPaymentOption(e.target.id),
    onReady: () => setInprogress(false),

    onChange: (ev) =>
      setFields((fields) => ({
        ...fields,
        [ev.field]: {
          value: ev.value,
          error: ev.error,
          complete: ev.complete,
        },
      })),

    subscribeToPlan: (paymentIntent) => {
      setInprogress(true)
      const req = onSubmit(selectedPlanId, paymentIntent, company)

      if (req instanceof Promise) {
        req.finally(() => setInprogress(false))
        return req
      }

      setInprogress(false)
      return Promise.resolve(req)
    },

    tokenizeAndSubmit: () => {
      if (paymentOption === "setup-later") {
        if (!user?.company && !company) return

        setInprogress(true)
        fns
          .subscribeToPlan({ company: user.company || company })
          .finally(() => setInprogress(false))
        return
      }

      if (!areFieldsValid(fields)) {
        console.log("fields not valid")
        return
      }
      setCardError(false)
      setInprogress(true)
      // 3DS Flow to capture card and pass authorised intent to create subscription
      cardRef.current
        .authorizeWith3ds(intent, { firstName: fields.nameOnCard.value })
        .then((paymentIntent) => {
          if (paymentIntent.status === "authorized") {
            return fns.subscribeToPlan(paymentIntent)
          } else {
            return Promise.reject()
          }
        })
        .catch((err) => {
          console.error(err)
          setCardError(true)
        })
        .finally(() => setInprogress(false))
    },
  }

  if (!selectedPlan) return null

  const btnDisabled =
    paymentOption === "add-card" ? !areFieldsValid(fields) : !company

  return (
    <Container className="order-summary">
      <H3 align="center">Subscription summary</H3>
      <OrderInfo plan={selectedPlan} />
      <Box height="22px" />
      {isLoading ? (
        <CenteredProgress />
      ) : paymentDetails?.status === "valid" ? (
        <>
          <Large align="center">Payment Details</Large>
          <CardSummary />
          <Box m={6} />
          <Button
            onClick={() => fns.subscribeToPlan({})}
            disabled={!!inProgress}
            fullWidth
          >
            {inProgress ? <CircularProgress /> : "Subscribe"}
          </Button>
        </>
      ) : cardRequired ? (
        <>
          <div className="border required-card-form">
            {cardError ? (
              <ErrorBox>
                <Text component="span" light style={{ padding: "5px" }}>
                  We are unable to authenticate your payment method. Please
                  choose a different payment method and try again.
                </Text>
              </ErrorBox>
            ) : null}
            <CardForm
              fns={fns}
              setNameOnCard={(ev) =>
                fns.onChange({
                  field: "nameOnCard",
                  complete: Boolean(ev.target.value.trim()),
                  value: ev.target.value,
                })
              }
            />
          </div>

          <Button
            fullWidth
            onClick={fns.tokenizeAndSubmit}
            className="btn-subscribe"
            disabled={!!inProgress || btnDisabled || loading}
            loading={inProgress || loading}
          >
            Subscribe
          </Button>
        </>
      ) : (
        <>
          <div className="border required-card-form">
            {cardError ? (
              <div className="error-msg">
                <ErrorBox>
                  <Text component="span" light style={{ padding: "5px" }}>
                    We are unable to authenticate your payment method. Please
                    choose a different payment method and try again.
                  </Text>
                </ErrorBox>
              </div>
            ) : null}
            <CardForm
              fns={fns}
              setNameOnCard={(ev) =>
                fns.onChange({
                  field: "nameOnCard",
                  complete: Boolean(ev.target.value.trim()),
                  value: ev.target.value,
                })
              }
            />
          </div>
          <Button
            fullWidth
            onClick={fns.tokenizeAndSubmit}
            className="btn-subscribe"
            disabled={!!inProgress || btnDisabled || loading}
            loading={inProgress || loading}
          >
            Get started
          </Button>
        </>
      )}
    </Container>
  )
}

const Row = ({ left, right = "", bold = false, Type = Text }) => (
  <Box display="flex" justifyContent="space-between">
    <Type light={!bold}>{left}</Type>
    {right && <Type light={!bold}>{right}</Type>}
  </Box>
)

const OrderInfo = ({ plan }) => {
  if (!plan) return null
  const toCurrency = (d$) => `$${d$} ${plan.currencyCode}`
  const words = plan?.name.split(" ")

  return (
    <>
      <Box p={4} />
      <Card style={{ textAlign: "center", backgroundColor: "#f0f4f9" }}>
        <Text light>Your subscription will start immediately.</Text>
        <Text light>
          You will be charged now for the first month and you can cancel at any
          point.
        </Text>
      </Card>
      <Box m={4} />
      <Card>
        <Box p={2} />
        <Large role="presentation" className="name1" component="span">
          {plan.id === "excel-2000"
            ? words.slice(0, 2).join(" ")
            : words.slice(0, 1)}
        </Large>
        <Large role="presentation" className="name2" component="span">
          {plan.id === "excel-2000"
            ? words.slice(2).join(" ")
            : words.slice(1).join(" ")}
        </Large>
        <Box p={1} />
        <Divider />
        <Box p={1} />
        {plan.id === "excel-2000" ? (
          <Row left={"Up to 3 users, billed monthly"} />
        ) : (
          <Row left={"Single user, billed monthly"} />
        )}
        <Box p={1} />
        <Divider />
        <Box p={1} />
        <Row Type={H4} bold left="Total" right={toCurrency(plan?.price)} />
      </Card>
    </>
  )
}

/*
 * This exists instead of using <PaymentMethod/> because I couldn't figure out
 * a simple way to extract the submit button so it could be placed elsewhere in
 * the page. Functionality should be very similar. It also reuses most of the
 * styles from PaymentMethod
 */
const CardForm = ({ fns, setNameOnCard }) => {
  const [cardUnsupported, setCardUnspported] = useState(false)

  const onChange = (ev) => {
    if (ev.field === "number") {
      const isSupported = ev.cardType && SUPPORTED_CARDS.includes(ev.cardType)
      setCardUnspported(!isSupported)
    }

    const next = { error: ev.error, complete: ev.complete, empty: ev.empty }
    if (ev.value) next.value = ev.value

    fns.onChange(ev)
  }

  return (
    <div className="payment-form inset">
      <div className="supported-cards">
        <Text light>Card types that we support:</Text>
        <div className="icons">
          <img src={visa} alt="Visa" />
          <img src={mastercard} alt="Mastercard" />
          <img src={amex} alt="American Express" />
        </div>
      </div>

      <Box className="field name">
        <Field
          xs={12}
          label="Name on card"
          placeholder="Name"
          onChange={setNameOnCard}
        />
      </Box>
      <CardComponent ref={fns.ref} onReady={fns.onReady} onChange={onChange}>
        <fieldset name="Card Number">
          <label
            className="MuiFormLabel-root MuiInputLabel-root"
            htmlFor="card-number"
          >
            Card Number
          </label>
          <CardNumber
            id="card-number"
            className={`field number ${
              cardUnsupported ? "unsupported" : "supported"
            }`}
          />
        </fieldset>

        <fieldset name="expiry" className="fullWidth">
          <label
            htmlFor="expiry"
            className="MuiFormLabel-root MuiInputLabel-root"
          >
            Expiration
          </label>
          <CardExpiry id="expiry" className="field expiry" />
        </fieldset>

        <fieldset name="cvv" className="fullWidth">
          <label htmlFor="cvv" className="MuiFormLabel-root MuiInputLabel-root">
            CVV
          </label>
          <CardCVV id="cvv" className="field" />
        </fieldset>
      </CardComponent>
    </div>
  )
}
