import React from "react"

import { Text } from "ui-elements/Typography"

import { usePaymentDetails, useCustomer } from "utils/api"
import { CenteredProgress } from "ui-elements/progress"
import "assets/styles/credit-card.css"
import { isEmpty } from "utils/helpers"

export const CardSummary = () => {
  const { customer, isLoading: fetchingCustomer } = useCustomer()
  const { paymentDetails, isLoading: fetchingCard } = usePaymentDetails()
  const card = paymentDetails?.card

  if (fetchingCustomer || fetchingCard) return <CenteredProgress />

  if (isEmpty(customer))
    return <Text light>You currently are not subscribed to any plan.</Text>

  if (!card) return <Text light>You do not have a valid card on file</Text>

  const fullName = `${card.firstName ?? "—"} ${
    card.lastName ?? ""
  }`.toUpperCase()
  const expiry =
    ("0" + card.expiryMonth).slice(-2) + "/" + String(card.expiryYear).slice(-2)

  return (
    <div className="credit-card">
      <Text className="card-number">
        {card?.maskedNumber.replace(/\*\*\*\*/g, "**** ")}
      </Text>
      <Text className="card-expiry">Exp. {expiry}</Text>
      <Text className="card-name">{fullName}</Text>
    </div>
  )
}
