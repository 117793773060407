import React, { useRef, useState, useEffect } from "react"

import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"

import {
  CardComponent,
  CardNumber,
  CardExpiry,
  CardCVV,
} from "@chargebee/chargebee-js-react-wrapper"
import ErrorBox from "ui-elements/ErrorBox"

import { createPaymentIntent } from "utils/api"
import Button from "ui-elements/Button"
import { Label, Text } from "ui-elements/Typography"

import Field from "ui-elements/Field"
import { Card } from "ui-elements/Card"

import "assets/styles/payment-method.css"
import visa from "assets/images/visa.svg"
import mastercard from "assets/images/mastercard.svg"
import amex from "assets/images/amex.svg"

const SUPPORTED_CARDS = ["visa", "mastercard", "amex"]
const areFieldsValid = (fields) =>
  Object.values(fields).reduce((a, x) => a && x.complete && !x.error, true)

export const PaymentMethod = ({ onSubmit, btnLabel = "Continue" }) => {
  const cardRef = useRef(null)
  const [inProgress, setInprogress] = useState(false)
  const [cardUnsupported, setCardUnspported] = useState(false)
  const [intent, setIntent] = useState(null)
  const [fetchedIntent, setFetchedIntent] = useState(false)
  const [cardError, setCardError] = useState(false)
  const [fields, setFields] = useState({
    cvv: {},
    expiry: {},
    nameOnCard: { value: "" },
    number: { empty: true },
  })

  useEffect(() => {
    if (!fetchedIntent) {
      createPaymentIntent({
        amount: 9900,
        currency: "USD",
        paymentMethodType: "card",
      })
        .then((intent) => {
          setIntent(intent.data)
          setFetchedIntent(true)
        })
        .catch((err) => console.log(err))
    }
  })

  const fns = {
    tokenizeAndSubmit: () => {
      setCardError(false)
      setInprogress(true)
      cardRef.current
        .authorizeWith3ds(intent, { firstName: fields.nameOnCard.value })
        .then((paymentIntent) => {
          if (paymentIntent.status === "authorized") {
            return onSubmit(paymentIntent)
          } else {
            return Promise.reject()
          }
        })
        .catch((err) => {
          console.error(err)
          setCardError(true)
          setInprogress(false)
        })
    },

    onChange: (ev) => {
      if (ev.field === "number") {
        const isSupported = ev.cardType && SUPPORTED_CARDS.includes(ev.cardType)
        setCardUnspported(!isSupported)
      }

      const next = { error: ev.error, complete: ev.complete, empty: ev.empty }
      if (ev.value) next.value = ev.value

      setFields((fields) => ({ ...fields, [ev.field]: next }))
    },
  }

  return (
    <>
      <Card elevation={0} className="payment-form standalone">
        {cardError ? (
          <div className="error-msg">
            <ErrorBox>
              <Text component="span" light>
                We are unable to authenticate your payment method. Please choose
                a different payment method and try again.
              </Text>
            </ErrorBox>
          </div>
        ) : null}
        <div className="supported-cards">
          <Text light>Card types that we support:</Text>
          <div className="icons">
            <img src={visa} alt="Visa" />
            <img src={mastercard} alt="Mastercard" />
            <img src={amex} alt="American Express" />
          </div>
        </div>
        <Box className="field name">
          <Field
            xs={12}
            label="Name on card"
            placeholder="Name on Card"
            onChange={(ev) =>
              fns.onChange({
                field: "nameOnCard",
                complete: Boolean(ev.target.value.trim()),
                value: ev.target.value,
              })
            }
          />
        </Box>

        <CardComponent ref={cardRef} onChange={fns.onChange}>
          <fieldset name="Card Number" className="card-number-container">
            <Label light className="label" htmlFor="card-number">
              Card Number
            </Label>
            <CardNumber
              id="card-number"
              className={`field number ${
                cardUnsupported ? "unsupported" : "supported"
              }`}
            />
          </fieldset>

          <fieldset name="expiry">
            <Label htmlFor="expiry" className="label">
              Expiration
            </Label>
            <CardExpiry id="expiry" className="field expiry" />
          </fieldset>

          <fieldset name="cvv">
            <Label htmlFor="cvv" className="label">
              CVV
            </Label>
            <CardCVV id="cvv" className="field" />
          </fieldset>
        </CardComponent>
        <Button
          onClick={fns.tokenizeAndSubmit}
          disabled={inProgress || !areFieldsValid(fields)}
          fullWidth
        >
          {inProgress ? <CircularProgress size="1.5rem" /> : btnLabel}
        </Button>
      </Card>
    </>
  )
}
