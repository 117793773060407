import React, { useState, useRef, useEffect } from "react"
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom"
import { mutate } from "swr"

import Alert from "@material-ui/lab/Alert"
import AlertTitle from "@material-ui/lab/AlertTitle"
import Box from "@material-ui/core/Box"
import Snackbar from "@material-ui/core/Snackbar"
import Container from "@material-ui/core/Container"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import CircularProgress from "@material-ui/core/CircularProgress"
import Backdrop from "@material-ui/core/Backdrop"
import Modal from "@material-ui/core/Modal"
import { Card } from "ui-elements/Card"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { makeStyles } from "@material-ui/core/styles"

import "assets/styles/account.css"
import { Billing } from "pages/Billing"
import { Button } from "ui-elements/Button"
import { H3, Text } from "ui-elements/Typography"
import { AllSet } from "components/AllSet"
import { OrderSummary } from "components/OrderSummary"
import { Plans } from "components/Plans"
import { Subscriptions } from "components/Subscriptions"
import { AccountDetails } from "pages/AccountDetails"
import { Downloads } from "pages/Downloads"

import {
  addSubscription,
  cancelSubscription,
  reactivateSubscription,
  useCustomer,
  createSubscription,
} from "utils/api"
import { isEmpty } from "utils/helpers"
import { Typography } from "@material-ui/core"

const getDefaultDialogOptions = (fns) => ({
  title: "Add more licenses",
  message: (
    <Text light component="span">
      Please contact our sales team to add more licenses to your subscription.
    </Text>
  ),
  actions: [
    {
      label: "Cancel",
      onClick: fns?.dialog.close,
      color: "default",
      variant: "outlined",
    },
    {
      label: "Contact Sales",
      onClick: fns?.goToSales,
      variant: "contained",
      color: "primary",
      autoFocus: true,
    },
  ],
})

const TabLink = ({ to, children }) => (
  <NavLink to={to} activeClassName="tab-active" className="tab-link">
    {children}
  </NavLink>
)

const Header = ({ url }) => (
  <>
    <Box m={10} />
    <H3>My account</H3>
    <Box m={10} />
    <div className="account-menu">
      <TabLink to={`${url}/details`}>Account details</TabLink>
      <TabLink to={`${url}/subscriptions`}>Manage subscriptions</TabLink>
      <TabLink to={`${url}/billing`}>Billing</TabLink>
      <TabLink to={`${url}/downloads`}>Downloads</TabLink>
    </div>
  </>
)
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
}))

export const Account = ({ triggerLogOut }) => {
  const classes = useStyles()
  const [alert, setAlert] = useState(null)
  const [headerVisible, setHeaderVisible] = useState(true)
  const [dialog, setDialog] = useState({})
  const [inProgress, setInprogress] = useState(true)
  const [formVisible, setFormVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const { customer } = useCustomer()

  // has to be initialised with a non-primitive so refrence can be mutated
  const refOnCardAdd = useRef({})
  const selectedPlanId = useRef("")

  let { path, url } = useRouteMatch()
  const history = useHistory()

  useEffect(() => {
    history.listen((loc) => {
      const headerVisible =
        loc.pathname.endsWith("order-summary") ||
        loc.pathname.endsWith("all-set")
          ? false
          : true
      setHeaderVisible(headerVisible)
    })
  })

  const fns = {
    showOrderForm: () => setFormVisible(true),
    closeOrderForm: () => setFormVisible(false),
    hideAlert: () => setAlert(null),
    dialog: {
      close: () => setDialog(false),
      open: (options) => {
        // When using the bare function as an event handler (e.g
        // `onClick={fns.dialog.open}`, the event will be the first parameter.
        // This guards against cases where the event is passed, instead of a
        // valid Dialog configuration
        if (!options?.hasOwnProperty("title"))
          options = getDefaultDialogOptions(fns)

        setDialog(options)
      },
    },

    goToAllSet: (selectedId) => {
      // Create trial subscription without payment details
      // for first time users coming from CSP.
      setInprogress(true)
      setLoading(true)
      selectedPlanId.current = selectedId
      // history.push(`${url}/all-set`)
      // Pass undefined paymentIntent
      // const paymentIntent = undefined
      addSubscription(selectedId)
        .then(() => {
          mutate("/ordering/subscription").then((subs) => {
            const subscription = subs.find((s) => s.planId === selectedId)
            setInprogress(false)
            fns.openAllSetPage(subscription?.status)
          })
        })
        .catch((e) => {
          const error = e?.data?.error
          if (error?.api_error_code === "payment_method_not_present") {
            setInprogress(false)
            return fns.goToSummary(selectedId)
          }

          const message =
            error?.api_error_code === "payment_processing_failed"
              ? `Subscription cannot be created. There was a problem with your payment details.
              Please check your card details are correct or contact support`
              : `There was a problem with your payment details.
            Please check your card details are correct or contact support.`

          setAlert({ severity: "warning", message })
          history.push(`${url}/subscriptions`)
        })
        .finally(() => {
          setInprogress(false)
          setLoading(false)
        })
    },

    goToSummary: (selectedId) => {
      selectedPlanId.current = selectedId
      refOnCardAdd.current = fns.subscribeToPlan
      // history.push(`${url}/order-summary`)
      fns.showOrderForm()
    },

    goToSupport: () => {
      const win = window.open(
        "https://support.arria.com/support/tickets/new",
        "_blank",
      )
      win.focus()
      fns.dialog.close()
    },

    goToSales: (category) => {
      const source =
        category === "excel"
          ? "isc-excel-contact-sales-btn"
          : "isc-bi-contact-sales-btn"
      const contactSalesURL = `https://www.arria.com/request-volume-pricing?source=${source}`
      const win = window.open(contactSalesURL, "_blank")
      win.focus()
      fns.dialog.close()
    },

    goToAddLicenses: (category) => {
      const source =
        category === "excel" ? "isc-excel-add-licences" : "isc-bi-add-licences"
      const contactSalesURL = `https://www.arria.com/request-volume-pricing?source=${source}`
      const win = window.open(contactSalesURL, "_blank")
      win.focus()
      fns.dialog.close()
    },

    confirmTrial: (selectedTrialPlan) => {
      const actions = [
        {
          label: "Cancel",
          onClick: fns?.dialog.close,
          variant: "outlined",
          color: "primary",
          style: { marginBottom: "30px" },
        },
        {
          label: "Get started",
          variant: "contained",
          color: "primary",
          autoFocus: true,
          style: { marginBottom: "30px" },
          onClick: () => {
            fns.dialog.close()
            fns.goToAllSet(selectedTrialPlan.id)
          },
        },
      ]
      fns.dialog.open({
        title: <H3 align="center">Trial confirmation</H3>,
        maxWidth: "md",
        message: (
          <Box
            p={20}
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              textAlign: "center",
              marginTop: "-15px",
            }}
          >
            <Text display="inline" component="span" light>
              You are about to start your {selectedTrialPlan.trialPeriod}-day
              trial for
            </Text>
            <Text display="inline" component="span">
              <br />
              {selectedTrialPlan.name}.
            </Text>
            <Box m={3} />
          </Box>
        ),
        actions,
        buttonAlignmentCenter: true,
      })
    },

    closeScroll: () => {
      fns.dialog.close()
      setTimeout(
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        }),
        300,
      )
    },

    openAllSetPage: (status) => {
      const actions = [
        {
          label: "Done",
          onClick: fns?.closeScroll,
          variant: "outlined",
          color: "primary",
          size: "large",
          style: { width: 260, marginBottom: "30px" },
        },
      ]
      fns.dialog.open({
        title: "",
        maxWidth: "sm",
        message: (
          <AllSet
            status={status}
            selectedPlanId={selectedPlanId.current}
            closeFn={fns.dialog.close}
          />
        ),
        actions,
        buttonAlignmentCenter: true,
      })
    },

    goToSupportForStudio: () => {
      fns.dialog.open({
        title: "Manage your plan",
        maxWidth: "sm",
        message: (
          <Text component="span" light>
            Please contact sales to manage your current subscription.
          </Text>
        ),
        actions: [
          {
            label: "Dismiss",
            onClick: fns?.dialog.close,
            variant: "text",
            size: "small",
          },
          {
            label: "Contact Sales",
            onClick: fns?.goToSales,
            color: "primary",
            autoFocus: true,
            size: "small",
          },
        ],
      })
    },

    goToPlans: (_category) => {
      // HACK. should use refs instead
      const el = document.querySelector(`#plans-${_category}`)
      el &&
        window.scrollTo({
          top: el.offsetTop + 100,
          behavior: "smooth",
        })
    },

    subscribeToPlan: (planId, paymentIntent, company = customer.company) => {
      setInprogress(true)
      const action = customer.customerId
        ? addSubscription({
            planId,
            paymentIntent,
          }) /* existing customer */
        : /* if user is subscribing for the first time (no chargebee profile) */
          createSubscription({
            company,
            email: customer.email,
            firstName: customer.firstName,
            lastName: customer.lastName,
            planId,
            paymentIntentId: paymentIntent?.id,
          })

      action
        .then(() => {
          mutate("/ordering/subscription").then((subs) => {
            const subscription = subs.find((s) => s.planId === planId)
            fns.closeOrderForm()
            setInprogress(false)
            fns.openAllSetPage(subscription?.status)
            history.push(`${url}/subscriptions`)
          })
        })
        .catch((e) => {
          const error = e?.data?.error
          if (
            error?.api_error_code === "payment_method_not_present" ||
            error?.api_error_code === "payment_processing_failed"
          )
            refOnCardAdd.current = fns.subscribeToPlan

          const message =
            error?.http_status_code === 409
              ? error.message
              : error?.api_error_code === "payment_processing_failed"
              ? `Subscription cannot be created. There was a problem with your payment details.
              Please check your card details are correct or contact support.`
              : `There was a problem with your payment details.
                 Please check your card details are correct or contact support.`

          setAlert({ severity: "warning", message })
        })
        .finally(() => setInprogress(false))
    },

    cancelSubscription: (sid) => {
      const actions = [
        {
          label: "Return",
          onClick: fns.dialog.close,
          color: "default",
          variant: "outlined",
          autoFocus: true,
        },
        {
          label: "Confirm",
          variant: "contained",
          color: "primary",
          style: { minWidth: "180px", borderColor: "var(--secondary)" },
          onClick: () => {
            fns.dialog.close()
            cancelSubscription(sid)
              .then(() => {
                mutate("/ordering/subscription")
                setAlert({
                  severity: "success",
                  message: "Subscription successfully cancelled",
                })

                setTimeout(
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  }),
                  200,
                )
              })
              .catch((e) => {
                setAlert({
                  severity: "warning",
                  message:
                    e.message ||
                    "Cancelling subscription failed because of a problem.",
                })
              })
          },
        },
      ]

      fns.dialog.open({
        title: "Cancel subscription",
        maxWidth: "sm",
        message: (
          <Text component="span" light>
            Are you sure you want to cancel?
          </Text>
        ),
        actions,
      })
    },

    reactivateSubscription: (sid) => {
      const actions = [
        {
          label: "Cancel",
          onClick: fns.dialog.close,
          color: "default",
          variant: "outlined",
          autoFocus: true,
        },

        {
          label: "Confirm",
          variant: "contained",
          color: "primary",
          onClick: () => {
            fns.dialog.close()
            reactivateSubscription(sid)
              .then(() => {
                setAlert({
                  severity: "success",
                  message: "Subscription successfully reactivated",
                })
                setTimeout(
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  }),
                  200,
                )
              })
              .catch(() => {
                setAlert({
                  severity: "warning",
                  message: "There was an error reactivating the subscription",
                })
              })
          },
        },
      ]

      fns.dialog.open({
        title: "Reactivate subscription",
        maxWidth: "sm",
        message: (
          <Text component="span" light>
            Are you sure you want to reactivate your subscription?
          </Text>
        ),
        actions,
      })
    },
  }

  return (
    <Container maxWidth="lg">
      {headerVisible && <Header url={url} />}

      <Switch>
        <Route exact path={`${path}/details`}>
          <AccountDetails
            fns={fns}
            notify={setAlert}
            triggerLogOut={triggerLogOut}
          />
        </Route>

        <Route exact path={`${path}/downloads`}>
          <Downloads />
        </Route>

        <Route exact path={`${path}/billing`}>
          <Billing url={url} continueFn={refOnCardAdd} notify={setAlert} />
        </Route>

        <Route exact path={`${path}/subscriptions`}>
          <Subscriptions fns={fns} />

          <Box m={10} />
          <Plans
            onSubmit={fns.goToAllSet}
            fns={fns}
            category="excel"
            alignPlan="flex-start"
          />
          <Plans
            onSubmit={fns.goToAllSet}
            fns={fns}
            category="bi"
            alignPlan="flex-start"
          />
        </Route>

        <Redirect from={`${path}/`} to={`${path}/details`} />
      </Switch>

      <Backdrop
        transitionDuration={600}
        className={classes.backdrop}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={formVisible}
        onClose={fns.closeOrderForm}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        className="center-content"
      >
        <Card
          style={{
            minWidth: 500,
            position: "absolute",
            overflow: "scroll",
            top: "5%",
            bottom: "5%",
            height: "90%",
            display: "block",
          }}
        >
          <IconButton
            aria-label="close"
            style={{ position: "absolute", top: 5, right: 5 }}
            onClick={fns.closeOrderForm}
          >
            <CloseIcon />
          </IconButton>
          <OrderSummary
            onSubmit={refOnCardAdd.current}
            selectedPlanId={selectedPlanId.current}
            user={customer}
            loading={inProgress}
          />
        </Card>
      </Modal>
      <Dialog
        open={!isEmpty(dialog)}
        onClose={fns.dialog.close}
        maxWidth={dialog.maxWidth || "xs"}
        className="account-dialog"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h5" align="center">
            {dialog.title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Text style={{ color: "#494949", textAlign: "center" }}>
            {dialog.message}
          </Text>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: dialog.buttonAlignmentCenter ? "center" : "center",
          }}
          className={dialog.actions?.length > 3 ? "vertical" : ""}
        >
          {dialog.actions?.map(({ label, ...rest }) => (
            <Button
              disableFocusRipple={true}
              key={label}
              size="small"
              {...rest}
            >
              {label}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
      <Snackbar
        autoHideDuration={3000}
        onClose={fns.hideAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!alert}
      >
        {alert && (
          <Alert
            variant="filled"
            severity={alert.severity}
            onClose={fns.hideAlert}
          >
            {<AlertTitle style={{ marginBottom: 0 }}>{alert.title}</AlertTitle>}
            {alert.message}
          </Alert>
        )}
      </Snackbar>
    </Container>
  )
}
