import React, { useState } from "react"
import Link from "@material-ui/core/Link"
import { Button } from "ui-elements/Button"
import { Label, Small, Text } from "ui-elements/Typography"
import Box from "@material-ui/core/Box"
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core"

import Avatar from "@material-ui/core/Avatar"
import powerBiLogo from "assets/images/icon-power-bi-square.svg"
import tableauLogo from "assets/images/icon-tableau-square.svg"
import excelLogo from "assets/images/icon-excel-square.svg"
import excelWorkbookLogo from "assets/images/excel-workbook-logo.svg"
import microsoftAppsourceLogo from "assets/images/microsoft-appsource.svg"
import Alert from "@material-ui/lab/Alert"
import { Card } from "ui-elements/Card"
import emptyIcon from "assets/images/empty.svg"
import CircularProgress from "@material-ui/core/CircularProgress"
import { isEmpty } from "utils/helpers"
import "../assets/styles/download-list.css"
import { makeStyles } from "@material-ui/core/styles"
import { useSubscriptions } from "utils/api"

const baseurl = process.env.REACT_APP_PLUGIN_DOWNLOAD_URL

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  itemExcel: {
    background: "white",
    paddingTop: "24px",
    paddingBottom: "24px",
    marginBottom: "20px",
    // borderLeft: "4px solid #207245",
  },
  itemBi: {
    background: "white",
    paddingTop: "24px",
    paddingBottom: "24px",
    marginBottom: "20px",
    // borderLeft: "4px solid #f2c80f",
  },
  itemTableau: {
    background: "white",
    paddingTop: "24px",
    paddingBottom: "24px",
    marginBottom: "20px",
    // borderLeft: "4px solid #3F639F",
  },
  excel: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginRight: "30px",
  },
  bi: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginRight: "30px",
  },
  download: {
    position: "absolute",
    left: "145px",
    top: "-5px",
  },
  instructions: {
    marginRight: "180px",
    marginTop: "5px",
    fontSize: "13px",
  },
  instructionsExcel: {
    marginRight: "180px",
    // marginTop: "5px",
    fontSize: "13px",
  },
  imageLink: {
    position: "absolute",
    left: "145px",
    top: "-12px",
  },
  linkImage: {
    height: "50px",
  },
}))

const downloadPlans = [
  {
    icon: excelLogo,
    description: {
      mainText:
        "Install the Arria for Excel add-in to instantly turn the data in your Excel workbook into real-time, insightful narratives—as if written by your company’s best subject matter experts.",
    },
    class: "classes.itemExcel",
    installInstruction:
      "https://docs.integrations.arria.com/Excel/en/get-the-arria-nlg-add-in-for-excel.html",
    download:
      baseurl +
      "/pubs3.studio.arria.com/files/Excel/ArriaforAccountants/latest",
    metaData: {
      category: "excel",
      appsourceDownloadUrl:
        "https://appsource.microsoft.com/en-us/product/office/WA200002020?tab=Overview",
    },
    name: "Arria for Excel",
    id: "excel-2002",
  },
  {
    icon: excelWorkbookLogo,
    description: {
      mainText:
        "Download the Arria for Accountants workbook for use with the Arria for Excel add-in to automate the production of consolidated financial reports.",
      note:
        "*Only an Arria for Accountants subscription is required to use this product.",
    },
    class: "classes.itemExcel",
    installInstruction:
      "https://docs.integrations.arria.com/Essentials/ArriaForAccountants/en/get-started-with-arria-for-accountants.html",
    download:
      baseurl +
      "/pubs3.studio.arria.com/files/Excel/ArriaforAccountants/latest",
    metaData: {
      category: "excel",
    },
    name: "Arria for Accountants",
    id: "excel-2000",
  },
  {
    icon: powerBiLogo,
    description: {
      mainText:
        "Install the Arria custom visual to achieve faster data understanding and speed to insights with Arria’s configurable, no-code narratives and natural language query capabilities.",
    },
    class: "classes.itemBi",
    installInstruction:
      "https://docs.integrations.arria.com/BI/PowerBI/en/151539-importing-and-updating-arria-for-power-bi.html",
    download: baseurl + "/pubs3.studio.arria.com/files/PowerBI/add-in/latest",
    metaData: {
      category: "bi",
    },
    name: "Arria for Power BI",
    id: "bi-apps-1001",
  },
  {
    icon: tableauLogo,
    description: {
      mainText:
        "Install the Arria custom visual to achieve faster data understanding and speed to insights with Arria’s configurable, no-code narratives and natural language query capabilities.",
    },
    class: "classes.itemTableau",
    installInstruction:
      "https://docs.integrations.arria.com/BI/Tableau/en/add-arria-for-tableau-to-a-dashboard.html",
    download: baseurl + "/pubs3.studio.arria.com/files/Tableau/add-in/latest",
    metaData: {
      category: "bi",
    },
    name: "Arria for Tableau",
    id: "bi-apps-1001",
  },
]

const downloadErrMsg =
  "Sorry there was a problem downloading the selected file. Please contact support."

const downloadResource = (url) => {
  const a = document.createElement("a")
  a.href = url
  a.dispatchEvent(new MouseEvent("click"))
}

const Download = ({ item, setAlert }) => {
  const {
    id,
    name,
    description,
    download,
    icon,
    installInstruction,
    metaData,
  } = item
  const classes = useStyles()
  const [inProgress, setInprogress] = useState(false)

  const fns = {
    hideAlert: () => setAlert(false),
    getDownload: (link) => () => {
      setAlert(false)
      setInprogress(true)
      const url = link
      fetch(url, {
        method: "GET",
        headers: {
          "Accept-Encoding": "gzip, deflate",
          Accept: "*/*",
          Connection: "keep-alive",
          "Content-Type": "application/octet-stream",
        },
        body: undefined,
      })
        .then((res) => {
          if (res.status === 409) {
            setAlert({
              severity: "error",
              message: downloadErrMsg,
            })
          } else {
            downloadResource(res.url)
          }
        })
        .catch((error) => {
          console.error(error)
          setAlert({
            severity: "error",
            message: downloadErrMsg,
          })
        })
        .finally(() => {
          setInprogress(false)
        })
    },
  }

  return (
    <ListItem
      className={
        metaData.category === "excel" ? classes.itemExcel : classes.itemBi
      }
    >
      <ListItemAvatar>
        <Avatar
          variant="square"
          alt={icon}
          src={icon}
          className={metaData.category === "excel" ? classes.excel : classes.bi}
        />
      </ListItemAvatar>
      <ListItemText
        disableTypography={true}
        primary={<Text style={{ fontSize: "16px" }}>{name}</Text>}
        secondary={
          <Small
            style={{ color: "var(--text-label)", maxWidth: "500px" }}
            light
          >
            {description.mainText}
            {description.note ? (
              <>
                <br /> <br />
                {description.note}
              </>
            ) : null}
          </Small>
        }
      />
      <ListItemSecondaryAction>
        {id === "excel-2002" ? (
          <>
            <Link
              variant="text"
              className={classes.instructionsExcel}
              href={installInstruction}
              size="small"
              disableRipple
              underline="always"
              target="_blank"
            >
              Install instructions
            </Link>
            <Link
              className={classes.imageLink}
              href={metaData.appsourceDownloadUrl}
              edge="end"
              target="_blank"
            >
              <img
                src={microsoftAppsourceLogo}
                alt="Microsoft Appsource"
                className={classes.linkImage}
              />
            </Link>
          </>
        ) : (
          <>
            <Link
              variant="text"
              href={installInstruction}
              size="small"
              disableRipple
              underline="always"
              target="_blank"
              className={classes.instructions}
            >
              Install instructions
            </Link>
            <Button
              className={classes.download}
              onClick={fns.getDownload(download)}
              edge="end"
              size="small"
              disabled={inProgress}
            >
              {inProgress ? <CircularProgress size="1.3rem" /> : "Download"}
            </Button>
          </>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export const Downloads = (props) => {
  let { subscriptions } = useSubscriptions()
  let downloadsToShow = []

  // Look for plans that are subscribed
  if (!isEmpty(subscriptions)) {
    const planFound = subscriptions.filter(
      (p) =>
        p.status === "active" ||
        p.status === "in_trial" ||
        p.status === "non_renewing",
    )
    if (planFound) downloadsToShow = [...planFound].map((i) => i.planId)
  }

  const excelPlan1 = downloadsToShow.find((i) => i === "excel-2000")
  const excelPlan2 = downloadsToShow.find((i) => i === "excel-2002")
  const biPlan1 = downloadsToShow.find((i) => i === "bi-apps-1001")
  const biPlan2 = downloadsToShow.find((i) => i === "bi-apps-1002")

  const excelPlan = excelPlan1
    ? ["excel-2000", "excel-2002"]
    : excelPlan2
    ? [excelPlan2]
    : []
  const biPlan = biPlan1 || biPlan2 ? ["bi-apps-1001"] : []
  downloadsToShow = [...excelPlan, ...biPlan]

  const filteredDownloadPlans = downloadPlans.filter((d) =>
    downloadsToShow.includes(d.id),
  )

  const [alert, setAlert] = useState()
  return (
    <>
      <Label component="span">Downloads</Label>
      <Box m={5} />
      {!isEmpty(alert) && (
        <Alert severity={alert.severity}>{alert.message}</Alert>
      )}
      <Box m={5} />
      {isEmpty(filteredDownloadPlans) && (
        <Card
          height="240px"
          width="350px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <img alt="Icon showing empty state" src={emptyIcon} />
          <Box m={2} />
          <Text style={{ color: "var(--grey-dark)" }} light>
            You have no downloads
          </Text>
        </Card>
      )}
      <List className="download-list" dense={false}>
        {filteredDownloadPlans.map((p, index) => {
          return (
            <Download key={index} item={p} setAlert={setAlert} {...props} />
          )
        })}
      </List>
    </>
  )
}
