import React from "react"
import Container from "@material-ui/core/Container"
import Link from "@material-ui/core/Link"

import { version } from "../../package.json"
import "assets/styles/footer.css"
import { Text } from "ui-elements/Typography"

const hash = process.env.REACT_APP_GIT_SHA

const Footer = () => (
  <footer>
    <Container maxWidth="lg">
      <Text light>© 2012 - 2021 Arria Limited. All rights reserved.</Text>

      <Text light>
        {process.env.REACT_APP_ENV !== "prod" && `${version} – ${hash}`}
      </Text>

      <Text light color="primary">
        <Link
          href="https://www.arria.com/contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Us
        </Link>
        <span className="separator">|</span>
        <Link
          href="https://docs.app.studio.arria.com/en/12743-terms-and-conditions.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </Link>
        <span className="separator">|</span>
        <Link
          href="https://docs.app.studio.arria.com/en/14068-privacy-policy.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
      </Text>
    </Container>
  </footer>
)

export default Footer
