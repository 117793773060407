import React, { useState } from "react"
import { Link as RouteLink, useLocation } from "react-router-dom"

import { makeStyles } from "@material-ui/core/styles"
import AccountIcon from "@material-ui/icons/AccountCircle"
import AppBar from "@material-ui/core/AppBar"
import Divider from "@material-ui/core/Divider"
import Link from "@material-ui/core/Link"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Popover from "@material-ui/core/Popover"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"

import logo from "assets/images/logo-with-text.png"
import Button from "ui-elements/Button"
import { useCustomer } from "utils/api"
import { isEmpty } from "utils/helpers"
import { Card } from "ui-elements/Card"
import { Text, Label } from "ui-elements/Typography"
import { getSearchParam } from "utils/helpers"

const Header = ({ logOut, authenticated }) => {
  const location = useLocation()
  const source = getSearchParam("source")
  const stage = getSearchParam("stage")
  const isSource =
    source === "power-bi-plugin" ||
    source === "excel-plugin" ||
    source === "tableau-plugin"
  const isStage = stage === "excel_done" || stage === "bi_done"
  const isResetStage = stage === "success"

  const showAccountBtn =
    location.pathname === "/signin" ||
    (location.pathname === "/reset" && !isResetStage) ||
    location.pathname === "/challenge" ||
    (location.pathname === "/signup" && isSource && !isStage)
      ? true
      : false
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const { customer /*, isLoading, error  */ } = useCustomer()

  const menu = {
    show: (ev) => setAnchorEl(ev.currentTarget),
    hide: () => setAnchorEl(null),
  }

  const onLogout = () => {
    menu.hide()
    logOut()
  }

  const accountBtnContent =
    location.pathname === "/signup" && isSource && isStage
      ? "My Account"
      : "Log in"

  const actionButton = !authenticated ? (
    showAccountBtn ? null : (
      <RouteLink to="/signin" className={classes.login}>
        <Button size="small" variant="contained">
          {accountBtnContent}
        </Button>
      </RouteLink>
    )
  ) : isEmpty(customer) ? (
    <Button variant="text" onClick={menu.show} size="small">
      Log out
    </Button>
  ) : (
    <Button
      aria-controls="simple-menu"
      aria-haspopup="true"
      onClick={menu.show}
      startIcon={<AccountIcon />}
      variant="text"
      className={classes.btnLoggedIn}
      size="small"
    >
      {customer ? customer.firstName : "Log out"}
    </Button>
  )

  return (
    <AppBar
      position="sticky"
      color="inherit"
      elevation={0}
      className={classes.container}
    >
      <Container maxWidth="lg">
        <Toolbar className={classes.toolbar}>
          {authenticated ? (
            <RouteLink to={"/account"} className={classes.logoContainer}>
              <img src={logo} alt="logo" className={classes.logo} />
            </RouteLink>
          ) : (
            <Link
              href="https://www.arria.com/"
              className={classes.logoContainer}
            >
              <img src={logo} alt="logo" className={classes.logo} />
            </Link>
          )}

          <section className={classes.buttons}>
            {actionButton}
            <Popover
              keepMounted
              id="account-menu"
              open={Boolean(anchorEl)}
              onClose={menu.hide}
              anchorEl={anchorEl}
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              className="account-popover"
            >
              <Card>
                <Label>{`${customer?.firstName} ${customer?.lastName}`}</Label>

                <Typography variant="caption">{customer?.email}</Typography>
                <Divider className={classes.divider} />
                <Link
                  href="https://support.arria.com/support/tickets/new"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Text light>Get support</Text>
                </Link>
                <Box m={2} />

                <Link onClick={onLogout} href="#">
                  <Text>Log out</Text>
                </Link>
              </Card>
            </Popover>
          </section>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  container: {
    borderBottom: "1px solid var(--grey-light)",
    [breakpoints.down("sm")]: {
      padding: "0 1rem",
    },
  },
  toolbar: {
    padding: "unset",
    display: "flex",
    justifyContent: "space-between",
    [breakpoints.up("md")]: {
      margin: "0 auto",
      width: "100%",
      height: "6rem",
    },
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    height: spacing(4),
    [breakpoints.down("xs")]: {
      overflow: "hidden",
      width: "65px",
    },
  },
  logo: {
    display: "block",
    height: "100%",
  },
  brandingText: {
    marginTop: "1%",
    display: "block",
    height: "100%",
  },
  line: {
    fontWeight: 400,
    margin: spacing(0, 0.5),
  },
  login: { textDecoration: "none" },
  btnLoggedIn: {
    maxWidth: "unset",
    padding: spacing(0, 5),
    textTransform: "initial",
    fontSize: 14,
  },
  divider: {
    margin: spacing(3, 0),
  },
}))

export default Header
